import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import "./App.css";

import NavLayout from "./NavLayout";
import Error from "./components/Error";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient=new QueryClient();

// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
// });

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
    <Routes>
      <Route path="/" element={<NavLayout />}>
        <Route path="" element={<Header data={landingPageData.Header} />} />
        <Route path="about" element={<About data={landingPageData.About} />} />
        <Route
          path="services"
          element={<Services data={landingPageData.Services} />}
        />
        <Route
          path="gallery"
          element={<Gallery data={landingPageData.Gallery} />}
        />
        <Route
          path="testimonials"
          element={<Testimonials data={landingPageData.Testimonials} />}
        />
        <Route path="team" element={<Team data={landingPageData.Team} />} />
        <Route
          path="contact"
          element={<Contact data={landingPageData.Team} />}
        />
        <Route
          path="*"
          element={< Error />}
        />
      </Route>
    </Routes>
    </QueryClientProvider>
 );
};

export default App;
