import React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Image } from "./image";
import { CircularProgress } from "@mui/material";

export const Team = (props) => {
const dataReturned=useQuery(
  {
    queryKey:["teamData"],
    queryFn:()=>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/team-member/?website=Individual`)
    .then((res)=>res.data),
  }
)

if(dataReturned.data){
  console.log(dataReturned.data);
}


  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div>
        <div id="row">
          {dataReturned.data
            ? dataReturned.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                     <Image
                      title={d.full_name}
                      email={d.email}
                      largeImage={process.env.REACT_APP_BACKEND_URL+d.image}
                      smallImage={process.env.REACT_APP_BACKEND_URL+d.image}
                    />

                    <div className="caption">
                      <h4>{d.full_name}</h4>
                      <p>{d.description}</p>
                      <p>{d.email}</p>
                    </div>
                  </div>
                </div>
              ))
            : <CircularProgress/>}
        </div>
      </div>
    </div>
  );
};
