import React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { CircularProgress } from "@mui/material";
export const Testimonials = () => {

const dataReturned=useQuery(
  {
    queryKey:["testimonialData"],
    queryFn:()=>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/testimonial/?website=Individual`)
    .then((res)=>res.data),
  }
)

if(dataReturned.data){
  console.log(dataReturned.data);
}

  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
          {dataReturned.data
            ? dataReturned.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={process.env.REACT_APP_BACKEND_URL+d.image} alt="" />{" "}
           
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.description}"</p>
                      <div className="testimonial-meta"> - {d.full_name} </div>
                <Stack spacing={1}>
           <Rating name="half-rating-read" defaultValue={parseInt(d.rating)} precision={0.5} readOnly />
                </Stack>
                    </div>
                  </div>
                </div>
              ))
            : <CircularProgress/>}
        </div>
      </div>
    </div>
  );
};
