import { Image } from "./image";
import React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CircularProgress } from "@mui/material";

export const Gallery = (props) => {

const dataReturned=useQuery(
  {
    queryKey:["galleryData"],
    queryFn:()=>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/gallery/?website=Individual`)
    .then((res)=>res.data),
  }
)

if(dataReturned.data){
  console.log(dataReturned.data);
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            This is the Gallery Page of RP Srijan Individual Website
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {dataReturned.isFetched
              ? dataReturned.data.map((d, i) => (
                  <div
                    key={`${d.id}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.name}
                      largeImage={process.env.REACT_APP_BACKEND_URL+d.image}
                      smallImage={process.env.REACT_APP_BACKEND_URL+d.image}
                    />
                  </div>
                ))
              : <CircularProgress/>}
          </div>
        </div>
      </div>
    </div>
  );
}

return <div>Hello</div>
};
